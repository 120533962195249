@import '../../../common/ui/colors';

//fix for devtools behavior -- rescale all vh
@function vh($size) {
    // @return $size * 1vh;
    @return $size * 0.91vh;
}

.levelFilter {
	// display: none;
	display: flex;
	justify-content: flex-end;
	width: 88%;
	margin: 0 auto;
	font-size: 1.4rem;
	text-align: right;
	.levelevel {
		display: inline-block;
		font-weight: 300;
		color: $deep_green;
		margin-right: 1.0em;
	}
	label {
		display: inline-flex;
		font-size: 1.4rem;
		font-weight: 300;
		color: $deep_green;
		cursor: pointer;
	}
	input {
		display: inline-block;
		margin-right: 0.3em;
		// margin-right: 3px;
		margin-left: 1.0em;
		border: 3px solid $deep_green;		
	}
}


.levelBody.panel-body {
	background-color: rgb(249, 249, 249);
}

.itemList#modules {
	margin-top: vh(2);
	width: 88%;
	margin-left: auto;
	margin-right: auto;
	background-color: $light_green;

	.moduleDivider {
		border-top-color: $deep_green;
		// width: 58vw;
		max-width: 50em;
		margin: auto;
		// height: 0.5px;
		opacity: 0.5;
	}

	.new .moduleDivider, .last_played .moduleDivider, .common:last-child .moduleDivider, .in ~ .moduleDivider {
		display: none;
	}

	.module {
		border-radius: 0;
		margin-top: 0;
		background-color: transparent;

		.panel-collapse {
			background-color: white;
		}

		.moduleBody {
			padding: 0;
		}
	}

	.panel-heading {
		padding: 0;
		cursor: pointer;
	}

	.moduleHeader {
		height: vh(8);
		padding-bottom: 0;
		border-radius: 0;
		text-align: left;
		padding-left: 1em;
		display: flex;
		align-items: center;

		h4.title {
			font-size: vh(3.2);
			color: $blackish;
			font-weight: 300;
			padding-bottom: 0.15em;	//to compensate vert centering for lowercase
		}

		//arrow-right
		&:before {
			display: block;
			width: 0;
			height: 0;
			border-top: vh(1.7) solid transparent;
			border-bottom: vh(1.7) solid transparent;
			border-left: vh(3) solid white;
			margin-right: 0.8em;
			transition: 0.70s;
			content: '';
		}
	}

	//arrow-down
	.module.expanded .moduleHeader:before {
		transition: 0.70s;
		transform: rotate(90deg);
	}

	.new .moduleHeader, .suggested .moduleHeader {
		background-color: $yellow;
		//arrow-right
		&:before {
			border-left: vh(3) solid $grey;
		}		
	}

	.last_played .moduleHeader {
		background-color: $grey;
	}

	.moduleShowAll {
		font-size: 1.5rem;
		color: #505050;
		text-transform: lowercase;
		text-align: left;
		margin-bottom: 1em;
	}

	.item {
		display: block;
		position: relative;
		padding-top: 1.5em;
		padding-bottom: 1.5em;
		background-color: white;
		// margin-bottom: 3px;
		border-top: 0.5px solid $blackish;
		cursor: pointer;

		&:first-of-type{
			border-top-style: none;
		}

		.title {
			// display: inline-block;
			color: $blackish;
			margin: 0;
			font-size: 2rem;
			line-height: 1.2;
			font-weight: 400;
			// max-width: 40vw;
			opacity: 0.8;
		}

		.description {
			font-size: 1.5rem;
			line-height: 1.2;
			margin-top: 0.5em;
			color: $deep_green;
			font-weight: 300;
			// text-transform: lowercase;
		}

		&:focus, &:hover {
			text-decoration: none;
			outline: none;
		}

		.level {
			font-size: 1rem;
			font-weight: 100;
			color: $blackish;
			// position: absolute;
			float: right;
			margin-top: 0.3em;
			margin-left: 0.5em;

			.icon-heart {
				color: $deep_green;
				opacity: 0.8;
			}

		}

		.list-item-new {
			position: absolute;
			top: -0.3em;
			left: -0.3em;
			background-color: $yellow;
			padding: 0.2em 0.3em;
			border-radius: 0.3em;
			box-shadow: 1.2px 2.4px 2.4px gray;
			font-size: 1rem;
			color: $deep_green;
			font-weight: 600;
			// opacity: 0.8;
		}
	}

	.item:hover {
		background-color: #f4faf2;
	}
}

// Root level because we need it to be available in modules and in the filter

.levelDigit {
	display: inline-block;
	height: 1.35em;
	width: 1.35em;
	margin-left: 0.6em;
	margin-right: 0.4em;
	border-radius: 50%;
	text-align: center;
	color: white;
	// font-size: vh(1.5);
	font-weight: 900;
	line-height: 130%;
	background-color: aqua;
	cursor: pointer;
	opacity: 0.7;
	&.selected{
		box-shadow: 0 0 3px grey;
		opacity: 1;
	}
}

.levelWord {
	display: inline-block;
	vertical-align: middle;
	margin-top: -0.8vh;
}

.levelDigit0 {
	background-color: #B4E6DC;
}

.levelDigit1 {
	background-color: #FFC8C8;
}

.levelDigit2 {
	background-color: #B4DC8C;
}

.levelDigit3 {
	background-color: #BEBEDC;
}

.firstVisit {
	// max-width: 10em;
	.firstVisitButton {
		display: block;
		margin: 2em auto;
		padding: 0.6em 1.2em 0.7em;
		border-radius: 0.2em;
		max-width: 12em;
		background-color: $light_green;
		font-size: 2rem;
		color: white;
		text-align: center;
		line-height: 1.0;
		color: $blackish;
		cursor: pointer;
		&:hover {
			text-decoration: none;
		}
	}
}