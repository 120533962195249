@import '../../common/ui/colors';

#root .user, #root .userMenu, #root .user .userMenu, .bonusLinkDialog {
	#mainContainer {
		width: 90%;
		max-width: 50em;
		margin: 0 auto;
	}
	a {
		color: inherit;
		&:hover, &:focus {
			text-decoration: none;
		}
	}
	h1 {
		margin-top: 0;
		margin-bottom: 0.2em;
		padding-left: 2em;
		font-size: 2rem;
		font-weight: 300;
		text-align: left;
		height: 2.5em;
		line-height: 2.4em;
		color: inherit;
		background-color: $light_green;
		text-transform: lowercase;


		.icon {
			width: 1.3em;
			position: relative;
			// border: 1px solid grey;
			// top: 0.1em;
			&.exit {
				transform: scale(0.75);
				margin-left: -0.1em;
				margin-bottom: 0.3em;
				margin-right: 0.4em;
			}
		}

		.iconWrapper {
			display: inline-flex; 
			align-items: center; 
			height: 100%;

			.iconBg {
				background-color: white; 
				border-style: solid; 
				border-radius: 0.5em; 
				border-width: 2px; 
				border-color: #888; 
				width: 2em;
				height: 2em; 
				line-height: 2; 
				font-size: 70%; 
				margin-right: 0.8em;
				padding: 0 0.1em;
				display: grid;
				justify-content: center;
				align-content: center;

				i:before {
					width: 100%;
					margin: 0;
				}

				svg {
					// height: 2.25rem;
					// width: 2.25rem;
				}
			}
		}
	}

}

#root .user {

	h1 {
		text-align: center;
		padding: 0;
	}

	.bonusesAre{
		text-align: center;
		margin-top: 1em;
	}

	.table-scroller{
		font-size: 1.4rem;
		max-height: 14.2em;  
		overflow-y: auto;
		margin-top: 1em;
	}

	.noRecords {
		padding: 0.9em 1em 0.9em 0;
		font-weight: 300;
		font-size: 1.5rem;		
	}

	.text {
		font-weight: 300;
		font-size: 1.5rem;		
	}

	.smaller-text {
		font-size: 1rem;
	}

	table {
		// margin-top: 1.4rem;

		th {
			text-align: center;
			font-size: 1.5rem;
			font-weight: 300;
			text-transform: lowercase;
			background-color: $grey;
			border-bottom: none;
			padding: 0.6em 0.8em;
			border-top: none;
			position: sticky;
			top: 0;
			// z-index: 10;
		}

		tr:first-of-type td {
			border-top: none;
		}

		&.data {

			td {
				border-right: 1px solid $grey;				
			}

			td:first-of-type {
				padding: 0.9em 1em 0.9em 0;
			}

			td:last-of-type {
				text-align: right;
				width: 80px;
				min-width: 7em;
				padding: 0.9em 0 0.9em 1em;
				border-right: none;

				&.bonusCredits {
					padding: 0.9em 1em;
				}
			}
			td.sum {
				text-align: right;
			}

			td.remains {
				padding: 0.9em 1em 0.9em 0;
				text-align: left;
				font-size: 1.5rem;
				border-top: none;
				border-bottom: 1px solid $grey;

				a {
					color: $deep_green;
					font-weight: 400;
				}
			}
	
		}



		td.bonusCredits {
			text-align: center;
		}

		td {
			vertical-align: middle;
			padding: 0.9em 1em;
		}

		td, td a {
			font-size: 1.4rem;
			color: $blackish;
			font-weight: 300;
			line-height: 1.2em;
		}

		td.bonusWhat, td.bonusCredits {
			border-top: none;
			border-bottom: 1px solid $grey;
		}

		tr.mypaymentsRow:last-of-type td {
			border-bottom: 1px solid $grey;
		}

		&.bonusHowTo .instructions td {
			vertical-align: top;
			font-size: 1.5rem;
			width: 50%;

			// border-left: 1px solid #ddd;

			// &:last-of-type {
			// 	border-right: 1px solid #ddd;
			// }
		}

		&.bonusHowTo td{
			text-align: center;
			&.cta {
				cursor: pointer;
				border: none;
			}		
		}

		.circle {
			display: block;
			font-size: 1.8rem;
			line-height: 1.4em;
			text-align: center;
			margin: 0.5em auto 0.5em;
			width: 1.5em;
			height: 1.5em;
			border-radius: 50%;
		}
		.yellow {
			background-color: $yellow;
		}
		.blue {
			background-color: $bluish;
		}
	}

	.mainContainer {

	}

	&.support {

		.what {
			margin-top: 2em;
			margin-bottom: 0.5em;
		}

		textarea {
			width: 100%;
			height: 13em;
			padding: 0.5em 0.8em;
		}

		.sendWrapper {
			margin-top: 1em;
			text-align: right;
			cursor: pointer;

			.send {
				text-transform: lowercase;
				// font-weight: 600;
				display: inline-block;
				background-color: $bluish;
				padding: 0.5em 1.2em;
				border-radius: 1em;
				box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.15);

				&:hover {
					box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.5);
					transform: translateY(-1px);
				}

				.icon {
					margin-left: 0.5em;
				}
			}
		}
	}

	.copyIcon {
		margin-left: 0.5em;
		color: #777777;
	}
}

.userMenu a {
	position: relative;
	display: block;
}

.smile-and-buy {
	margin: 2.1em auto;
	display: flex;
	justify-content: center;

	.libo {
		width: 6em;
		opacity: 0.7;
	}

	.yellowButton {
		display: inline-block;
		padding: 0.6em 1.2em 0.7em;
		margin-left: 0.5em;
		background-color: $yellow;
		border-radius: 2em;
		cursor: pointer;

		font-size: 1.8rem;
		text-align: center;
		color: $blackish;
		white-space: initial;
		font-weight: 300;
		line-height: 1em;

		.buy, .more{
			display: block;
		}
		.buy {
			text-transform: uppercase;
		}
		.more {
			white-space: nowrap;
		}
	}
}

.accountMenuDialog{
	position: absolute;
	background-color: white;
	z-index: 2;
	// width: stretch;
	// border-top-left-radius: 0.5em;
	// border-bottom-left-radius: 0.5em;
	border-top: none;
	top: 10vh;
	// bottom: 0;
	right: 0;
	left: 0;
	padding: 6em 4em;
	box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.5);
	transform: translate(100%);
	opacity: 0;
	transition: transform 0.3s, opacity 0.3s;

	&.visible {
		opacity: 1;
		transform: translate(0);
	}

	@media (orientation: landscape) {
		left: auto;
		min-width: 35em;
	}

	@media (max-width: 600px) {
		bottom: 0;
	}
}

.bonusLinkDialog, .pleaseReferPopup {

	.modal-dialog {
		height: stretch;
	}

	.modal-body {
		// padding: 2em 3em;

		div {
			margin: 1em auto;
		}

		.btn {
			text-transform: lowercase;
		}

		.copyIcon {
			margin-left: 0.3em;
			color: #777777;
		}

		.social-container {
			margin: 0;
		}

		.social {
			display: flex;
			justify-content: space-evenly;
		}
	}

	h1:last-of-type {
		margin-bottom: 0;
	}
}

//MySuccess
.weeklyStats {
	.recharts-line {
		stroke: $deep_green;
	}
	.recharts-dot {
		fill: $deep_green;
	}
}

.successPrimary {
	color: $deep_green;
	font-size: 2rem;
}

.successSecondary {
	// color: $grey;
	font-size: 1rem;
	// display: inline-block;
	margin-left: 0.3em;
}

.remindersDay {
	margin: 1em auto;
	display: flex;
	max-width: 24em;
	justify-content: space-between;

	.reminderDay {
		padding: 0.3em 0.5em;
		border: 1px solid $deep_green;
		border-radius: 0.5em;
		cursor: pointer;
		font-size: 16px;

		&:hover {
			box-shadow: 0 6px 12px -2px rgba(50,50,93,0.25), 0 3px 7px -3px rgba(0,0,0,0.3);
			transform: translate(0, -1px)
		}

		&.selected, &:active {
			background-color: $deep_green;
			color: #eee;
			// font-weight: 600;
		}
	}
	
}

.remindersTime {
	max-width: 8em;
	margin: 1em auto;
	text-align: center;
	input {
		font-size: 2rem;
		height: auto;
		padding: 0.2em 0.3em;
		text-align: center;
	}
}

.remindersSelector {
	color: $deep_green;
	cursor: pointer;
	padding: 0.3em 0 0.1em;
	margin: 0 0.3em;
}

#root .user .remindersButton {
	margin: 2em auto;
	text-align: center;

	a.react-add-to-calendar-recurring__button {
		background-color: $deep_green;
		color: white;
		box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.15);
	}

	.react-add-to-calendar-recurring__dropdown ul {
		padding-inline-start: inherit;
	}
}

//favorites/cards
.favPhrasePair {
	font-size: 2rem;
	line-height: 1.2;
	background-color: white;
	width: 100%;	//for overflow
	overflow: auto;	//for the float
	margin: 0.5em auto;
	padding: 0.2em 0.4em;
	border-radius: 0.2em;
	box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px, rgba(0, 0, 0, 0.2) 0px 3px 1px -2px;
	&:hover {
		 box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.15);
		 transform: translateY(-1px);
	}

	.favPhraseTranslated {
		color: $deep_green;
		margin-top: 0.4em;
	}

	.icon-trash-empty {
		float: right;
		cursor: pointer;
	}
}

.userPageText {
	font-size: 1.5rem;
	margin-top: 2em;
}


// Auth0 dialog header fix
body .auth0-lock.auth0-lock {
	.auth0-lock-header, .auth0-lock-header-bg {
		background-color: $deep_green;
		.auth0-lock-name{
			display: none;
		}

	}	
}
