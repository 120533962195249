@import '../../../common/ui/colors';

/* .mainLayout #mainContainer {
	padding-top: 0;
}
*/

.player #mainContainer {
	margin-top: 3em;
}

#levelWrapper {
	display: inline-block;
	position: relative;	/*so that we can have absolute position inside*/
	vertical-align: top;
	margin-left: -10px;
	margin-top: -20px;
}

#levelLabel{
	display: inline-block;
	border-radius: 0 0 10px 10px;
	padding-top: 14px;
	padding-bottom: 14px;
	padding-left: 18px;
	padding-right: 18px;
	font-size: 18px;
	line-height: 16px;
	color: #86ac9f;
	text-transform: lowercase;
}
/*#levelLabel:before {
	display: inline-block;
	width: 16%;
	height: 20px;
	background-color: grey;
	content: ' ';
	position: absolute;
}*/

#levelLabel a{
	color: #86ac9f;
}

.inverse-round {
	display: inline-block;
	width: 10px;
	height: 10px;
	background-color: white;
	vertical-align: top;
	position: relative; /*so that it's above the absolutely positioned light background'*/
}

/*.inverse-round:after {
	display: inline-block;
	position: relative;
	top: 0;
	height: 10px;
	width: 10px;
	background-color: yellow;
	height: 20px;
	content: '*'
}*/

/* #title {
	display: inline-block;
	flex-grow: 1;  /*to push the chooser to the right*/
/*	vertical-align: bottom;
	margin-top: 0;
	margin-bottom: 0px;
	padding-bottom: 10px;
} */

#chooserAndStats {
    float: right;
    padding-top: 18px;
    /*padding-right: 14px;*/
}

#chooseAnother {
    display: inline-block;
	vertical-align: top;
	text-align: center;
	color: #86ac9f;
    border: #cad5db 1px solid;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 8px;
    padding-right: 8px;
    text-transform: lowercase;
	cursor: pointer;
	border-radius: 8px;
}


/*main text*/
#originalAndTranslated {
    font-size: 2.4rem;
	height: 11em;
    // margin-top: 1em;
	position: relative; //for #translation_player

	@media (min-width: 56rem) {
		height: 6em;
	}
}


.caption {
	display: grid;
	color: #6f9185;
	font-size: 2.4rem;
	/*font-weight: bold;*/
	line-height: 1.3;
	height: 50%;
	padding-top: 16px;
	padding-bottom: 22px;
	position: relative; /*for the translation sound icon*/
}

.caption#original{
    color: $blackish;
    span{
        align-self: end;
    }
}

.caption span {
	display: inline-block;
	// overflow-y: visible;    /*so that we don't see scrolling on big lines*/
    // max-height: 65px;

    line-clamp: 3;
    -webkit-line-clamp: 3;
    display: -webkit-inline-box;
    // box-orient: vertical;
    -webkit-box-orient: vertical;
    overflow-y: hidden;	/*we need this to hide the 4th line*/
}

// favorite/hide
.fav {
	// float: right;
	position: absolute;
	right: 7vw;
	top: 0.5em;
	color: $deep_green;
	z-index: 1; //to place it above captions

	@media (min-width: 480px){
		right: 1.5rem;
	}

	i {
		cursor: pointer;
		font-size: 2rem;
		opacity: 0.5;
		margin-left: 0.2em;

		&.selected {
			opacity: 1;
		}
	}

	i:hover {
		color: $deep_green;
		text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.5);
	}	

}

.bookAndPlay {
    position: absolute;
    right: 0;
	bottom: -1em;
	color: $deep_green;

	.btn {
		padding: 0;
	}
	
	.btn:hover {
		color: $deep_green;
		text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.5);
	}
	
	i {
		font-size:2rem;
	}
}

#textSeparator {
	background-color: #cad5db;
	height: 1px;
	/*margin-top: 1.9%;*/
}

#playerProgressWrapper {
	position: relative;
	margin-top: 3em;
	/*padding-left: 14px;
	padding-right: 14px;*/
	padding-bottom: 15px;

	@media (max-height: 400px){
		margin-top: 0;
		padding-bottom: 0;
	}
}

#playerProgressBackground {
	height: 6px;
	border-radius: 3px;
	background-color: $light_green;
}

#playerProgress {
	display: inline-block;
	height: 6px;
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
	position: absolute;
	top: 0;
	background-color: #86ac9f;
}

#playerProgressFixedPart {
	display: inline-block;
	height: 6px;
	width: 1px;
	position: absolute;
	top: 0;
	background-color: #86ac9f;
}

#playerPosition {
    display: inline-block;
    height: 20px;
    width: 20px;
    position: absolute;
    top: -7px;
	/* left: 3px; */
    background-color: #86ac9f;
    border-radius: 10px;
}

/*NAVIGATION*/
#navigation {
	margin-top: 0.8em;
	/*padding-right: 10px;*/
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

#navigation #translationTypeControls {
	display: inline-block;
	/*vertical-align: top;*/
	text-transform: lowercase;
	/*margin-top: -5px;*/
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 6px;
	border: 1px solid #cad5db;
	color: #6f9185;
	border-radius: 10px;
	order: 1;
}

#navigation #controlsWrapper {
	position: relative;
	display: flex;
	justify-content: center;
	vertical-align: top;
	flex: 1 0 auto;
	order: 2;
	text-align: center;

	#playPauseWrapper {
		display: inline;

		.speeder {
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: transparent;
			color: $deep_green;
			font-size: 3rem;
			font-weight: 600;
			line-height: 0;
			margin: 0.2em auto;
			padding: 0;
			padding-bottom: 0.3em;
			height: 2rem;
			width: 50%;
			border-radius: 1px;
		}
	}

	.speed-info {
		position: absolute;
		background-color: $light_green;
		background-image: none;
		color: $deep_green;
		border-radius: 1px;
	}
}

#navigation #repeaterWrapper {
	display: inline-block;
	padding-left: 8px;
	padding-right: 10px;
	padding-top: 6px;
}


#repeaterWrapper #repeater.enabled i {
	color: #86ac9f;
}

/*reset Angular styles*/
.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus, .btn.active, .btn:active {
	outline: none;
    box-shadow: none;
    color: $deep_green;
}

/*stats*/
#navigation #statsWrapper {
	display: inline-block;
	position: relative;
    order: 4;
    height: 74px;
    width: 74px;
    transform: scale(0.7); /*so that we don't have to change all sizes*/

    #statsFrame {
        position: absolute;
        left: 0;
        overflow: visible;	/*the circle inside can be 1px bigger */

        circle {
            stroke: $light_green;
        }
    }

    .CircularProgressbar-path {
        stroke: $light_green;
    }

}


#statsText {
	color: #86ac9f;
	// font-weight: 600;
	// line-height: 16px;
	position: absolute;
	width: 100%;
	top: 50%;
	margin-top: -12px;
	text-align: center;
}

#progressArc {
	display: inline-block;
	left: 0;
}

#statsWrapper md-progress-circular path {
	stroke: #d3ece8;
}


#statsWrapper md-progress-circular {
	float: left;	/*so that we don't get any whitespace below*/
}

/* BUBBLES */
.bubbleWrapper {
	position: absolute;
	left: 37px;
	top: 1.5em;
	transition: 4s ease-out;
}
.bubbleWrapper.up {
	opacity: 0.5;
}
.bubble {
	width: 1em;
	height: 1em;
	border-radius: 100%;
	transition: 4s ease-in;
	// display: none;
}

.bubble.up {
	width: 2em;
	height: 2em;
	transform: translate(0,-120vh);
	// display: block;
}

/*play/pause button*/
#controlsWrapper .btnPlayPause {
	margin: 0 10px;
	background-color: $light_green;
	height: 50px;
	width: 50px;
    border-radius: 25px;
    i {
        font-size: 44px;
        // vertical-align: top;
        /* display: inline-block; */
        height: 100%;
        width: 100%;
        line-height: 0;

    }
}

#controlsWrapper .btnPlayPause i:before {
	margin-left: 0;
	margin-right: 0;
	// line-height: 44px;
}

.icon-play {
	padding-left: 4px;	/*play icon should be moved to the right a bit*/
}

// #controlsWrapper #forwarder {
// 	margin-left: 12px;
// }

#repeaterWrapper #repeater i {
	font-size: 32px;
	color: #d3ece8;
	/* padding-right: 2px; */
}


/*cw icon*/
.icon-cw {
    display: inline-block;
    padding-bottom: 2px;
}
.icon-cw:before {
    transform: rotate(-30deg);
}

/*button*/
.player-btn {
	color: $deep_green;
	padding: 0;
    background-color: white;
    &:focus, &:active, &:hover{
        color: $deep_green;
    }
}

/*normal/literal*/
md-radio-button {
	margin-bottom: 2px;
}
md-radio-button .md-label {
	margin-left: 24px;
	line-height: 22px;  /*for vert position*/
}
md-radio-button .md-off {
	border-color: #cad5db !important;
}

md-radio-button.md-checked .md-on {
    background-color: #6f9185;
	transform: scale(0.25);
}

/*quick navigation*/

#phraseNavWrapper {
    margin-top: 24px;
    border-style: none;
}

.students.player{

    .panel-heading {
        background-color: $light_green_transparent;
        background-image: none;
        border-style: none;
		border-width: 0;
		height: 2.8em;

        h4{
            color: $blackish;
        }
	}
	

	// DOWNLOAD
	.downloadText {
		background-color: $yellow;
	}	

}

.captionLink {
	cursor: pointer;
	font-size: vh(3);
	color: #929292;
	margin-bottom: 0;
	line-height: vh(4);
	margin-top: vh(3);
}

.captionLinkActive {
	color: #86AC9F;
	font-weight: bold;
	/*text-decoration: underline;*/
}

.captionLink:hover, .captionLink:active {
	text-decoration: underline;
	outline: none;
}

@media (max-width: 800px) {

	#levelLabel {
		 font-size: 12px;
		 padding-top: 5px;
		 padding-bottom: 8px;
		 padding-left: 12px;
		 padding-right: 12px;
	}

	#titleAndChooser {
		display: inline-block;
		max-width: 45%;
		vertical-align: top;
		position: relative;
		left: -8px;
	}

	#title {
		/* font-size: 16px;
		margin-bottom: 0px;
		padding-bottom: 4px;
		margin-left: 5px; */
		/*vertical-align: top;*/
		/*position: relative;
		top: 12px;*/
	}

	#chooseAnother {
		font-size: 10px;
		line-height: 12px;
		/*width: 80px;*/
		white-space: nowrap;
		margin-left: -8px;
		margin-top: 0;
		margin-left: 0;
		padding-top: 3px;
		padding-bottom: 5px;
	}

	#statsWrapper {
		transform: scale(0.9);
		left: 4px;  /*to compensate for scaling: 74/2 * (1 - 0.9) ~ 3.7px*/
	}

	/*CAPTIONS*/
	// #originalAndTranslated {
	// 	margin-top: 2.1%;
	// 	height: 40%;
	// }

	// .caption {
	// 	padding-top: 8px;
	// 	padding-bottom: 15px;
	// 	font-size: 24px;
	// 	line-height: 29px;
	// 	height: 50%;
	// }

	// #textSeparator {
	// 	height: 1px;
	// 	margin-top: 1.9%;
	// }


	/*PLAYER PROGRESS*/
	// #playerProgressWrapper {
	// 	margin-top: 7%;
	// }

	/*NAVIGATION CONTROLS*/
	// #navigation {
	// 	margin-top: 0;
	// }

	#navigation #translationTypeControls {
		padding-left: 14px;
		padding-top: 3px;
		padding-bottom: 3px;
	}

	#translationTypeControls .md-label {
		font-size: 13px;
		margin-left: 24px;
	}

	#translationTypeControls md-radio-button {
		margin-bottom: 0;
	}

	/*PLAY PAUSE FFD REWIND*/
	/* #controlsWrapper {
		margin-bottom: 15px;
		margin-left: 12px;
	} */

	/*play/pause button*/
	#controlsWrapper .btnPlayPause {
		margin: 0 8px;
		height: 46px;
		width: 46px;
		border-radius: 23px;
	}

	#controlsWrapper .btnPlayPause i {
		font-size: 40px;
        // line-height: 44px;
        padding-bottom: 2px;
    }

	/*LOOP PHRASE*/
	#controlsWrapper #repeaterWrapper {
		padding-left: 0;
		padding-right: 30px;
		margin-bottom: 7px;
	}

	#repeaterWrapper #repeater i {
		font-size: 40px;
	}

	/*STATS*/


	/*QUICK PHRASE NAV*/
	.panel-heading h4 {
		font-size: 1.8rem;
		line-height: 1.5em;
	}

	.panel-heading a {
        /* width: 80%;		  */
        display: block;
	}

	.panel-heading i {
		width: 10%;
	}

	.panel-heading i:before {
		/*left: -40%;*/
	}

	// .captionLink {
	// 	font-size: 16px;
	// }

	#gorizont_mob{ display: initial;}
	#vertikal_mob{ display: none;}

 }

@media (max-width: 480px) {
	.player #mainContainer {
		margin-top: 0em;
	}
	
	#gorizont_mob{ display: none;}
	#vertikal_mob{ display: initial;}

	#levelLabel {
		line-height: 14px;
		padding-left: 10px;
		padding-right: 10px;
	}

	#title {
		margin-left: 0;
	}

	#chooseAnother {
		width: 75px;
		white-space: normal;
		padding-right: 4px;
		padding-left: 4px;
	}

	/*TEXT*/
	// #originalAndTranslated {
	// 	margin-top: 5%;
	// }
	// .caption {
    //     line-height: 27px;

	// }
	// .caption span {
    //     /* styles below allow for ellipsis-style trimming */
    //     max-height: 120px;
	// }
	#textSeparator {
		margin-bottom: 2.2%;
	}

	/*PLAYER*/
	// #playerProgressWrapper {
    //     margin-top: 20%;
    //     padding-bottom: 5px;
	// }

	#navigation {
		flex-wrap: nowrap;
	}

	/*PLAY ETC*/
	#navigation #controlsWrapper {
		order: 2;
		/* width: 100%; */
		/* margin-left: 4px; */

	}
	// #navigation #controlsWrapper #forwarder {
	// 	margin-left: 6px;
	// }

	#controlsWrapper .btnPlayPause {
		margin: 0 4px;
	}

	#controlsWrapper .btnPlayPause i {
		// line-height: 44px;
	}


	/*TRANSLATION TYPES*/
	#navigation #translationTypeControls {
		order: 2;
		/*margin-top: -8px;*/
	}
	#translationTypeControls .md-label {
		font-size: 12px;
		margin-left: 22px;
		line-height: 14px;
		padding-bottom: 4px;
	}

	#translationTypeControls md-radio-button {
		line-height: 18px;	/*influences distance between radios*/
	}

	#translationTypeControls md-radio-button .md-off, #translationTypeControls md-radio-button .md-container, #translationTypeControls md-radio-button .md-on {
		height: 16px;
		width: 16px;
	}


	/*LOOP PHRASE*/
	#navigation #repeaterWrapper {
		order: 1;
		padding-right: 0;
		padding-top: 0;
	}
	#navigation #repeaterWrapper #repeater {
		/*margin-bottom: 20%;*/
		/* margin-left: 35%; */
	}
	#navigation #repeaterWrapper #repeater i {
		font-size: 36px;
	}

	/*PHRASE NAVIGATION*/
	#phraseNavWrapper {
		margin-top: 2em;
	}

}

@media (max-width: 320px) {
    .caption span {
        font-size: 20px;
    }
}

@media (max-width: 280px) {
    .caption span {
        font-size: 16px;
    }

    /* a quick shrink so that all nav fits on one line */
    #navigation > div {
        margin-left: -5px;
        margin-right: -5px;
    }
}

// .exercise {
// 	padding: 1em 1.2em;
// }

.exercise-phrase {
	font-size: 1.4rem;
	color: $blackish;
	margin-top: 1em;
	.form-control {
		display: inline-block;
		width: auto;
		// vertical-align: middle;
		padding: 1px 3px;
		height: 1.5em;

		&.correct {
			color: $deep_green_sat;
			font-weight: bolder;
		}
		&.incorrect {
			color: #ac4848;
			font-weight: bolder;
		}
	}
}

.toastify-flyent {
	position: absolute;
	background-color: $light_green;
	background-image: none;
	color: $deep_green;
	border-radius: 1px;
	bottom: 0;
	width: fit-content;
	left: -50%;
	margin-left: -50%;
}
