@import '../../../common/ui/colors';

.pay {
	line-height: 1.2;
	div {text-align: center;}
}

.pay-left {
	width: 70%;
	padding: 1em 0.7em;
	margin: 0.8em auto;
	text-align: center;
	border: 1px solid $grey;
	border-radius: 1.5em;
	font-size: 1.6rem;
	line-height: 1.3;

	em {
		font-weight: 600;
		font-style: normal;
		display: block;
	}
}

.pay-new-text {
	width: 50%;
	margin: 2em auto 0 auto;
	padding: 1em;
	background-color: $light-green;
	border-radius: 1em;
	display: flex;
	align-items: center;
	justify-content: space-around;

	.libo {
		// margin-left: 10%;
		width: 6em;
	}

	.text {
		display: inline-block;
		width: auto;
		padding-bottom: 0.1em;
		opacity: 0.5;
		font-size: 2.2rem;
		line-height: 0.8em;
		// font-style: italic;
		color: $blackish;
		text-transform: lowercase;
	}

}

.pay-after {
	margin: 2em auto 0 auto;
	width: 80%;
	text-align: center;
	font-size: 1.6rem;
	line-height:1.54;
}

.pay-total {
	margin: 1em auto 0 auto;
	font-size: 2.5rem;
	font-weight: 600;
	color: $deep-green;
}

.pay-buy {
	margin: 2em auto 0 auto;
	width: fit-content;
	display: flex;

	div {
		font-size: 2.2rem;
		text-align: center;
		height: 1.9em;
		padding: 0.85em 0;
		border: 1px solid $grey;
		line-height: 0;
	}

	.square {
		width: 1.9em;
	}
}

.pay-button {
	font-size: 2.2rem;
	height: 1.9em;
	background-color: $deep-green;
	color: white;
	font-weight: 400;
	text-transform: lowercase;
	line-height: 0;
	width: 5.7em;
	padding: 0.80em 0.7em;
	border-style: none;
	margin: 0 auto;
	opacity: 0.7;

	&.disabled {
		cursor: not-allowed;
	}

	&.pay-subscribe {
		margin-top: 1em;
		width: auto;
		display: inline-block;
		border-radius: 1em;
		box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.15);

		&:hover {
			box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.5);
			transform: translateY(-1px);
		}
		
		@media (max-width: 400px) {
			font-size: 1.8rem;
		}
	}
}

.pay-subscribe2 {
	margin-top: 0.5em;
	font-size: 1.8rem;
}

.pay-methods {
	margin: 1.8em auto;
	max-width: fit-content;
	border: 1px solid $grey;
	border-radius: 0.3em;
	box-shadow:  0 0 #0000,  #0000, 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
	font-size: 1.2rem;

	.pay-method {
		padding: 0.7em 0.6em;
		margin: 0;
		display: flex;
		align-items: center;
		cursor: pointer;
		border-radius: 0.3em;

		.pay-card-logo  {
			padding: 0 0.5em;
			img {
				width: 2em;
			}
		}

		.pay-card-details {
			margin: 0 0.3em;		

			.pay-card-details-top {
				font-weight: 900;
				margin-bottom: 0.2em;
				text-transform: capitalize;
			}

			div {text-align: left;}
		}

		&:hover {
			background-color: #eee;
			box-shadow:  0 0 #0000,  #0000, 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
		}
	}
}

.pay-or {
	font-size: 2rem;
	text-transform: uppercase;
	margin-top: 1em;
}

.pay-click {
	cursor: pointer;
}

.pay-thanks {
	text-align: center;
	margin-bottom: 3rem;
	font-size: 3rem;
}

.pay-back-button {
	display: block;
	width: 10em;
	margin: auto;
	text-align: center;
	background-color: $deep-green;
	color: white;
	font-size: 2.0rem;
	font-weight: 400;
	text-transform: lowercase;
	padding: 0.60em 0.4em;
	border-style: none;
	
	&:active, &:focus, &:hover {
		text-decoration: none;
		color: white;
		font-weight: 600;
	}
}

.pay-campaign-part1 {
	font-size: 1.3rem;
	margin-top: 1.5em;
	font-weight: 900;
}

.pay-campaign-part2 {
	font-size: 1.2rem;
	margin-top: 0.2em;
}

.pay-error {
	margin: 1em auto;
	font-size: 1.8rem;
	color: firebrick;
}