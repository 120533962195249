@import '../../common/ui/colors';

//fix for devtools behavior -- rescale all vh
@function vh($size) {
    // @return $size * 1vh;
    @return $size * 0.91vh;
}

@function vhl($size) {
    // @return $size * 1vh;
    @return $size * 1.6 * 0.91vh;
}

@function vw($size) {
	@return $size * 1.0vw;
}

@function vf($size) {
	@return $size * 0.5rem;
}

html {
	@media (min-width: 800px) {
		font-size: 16px;
	}
}

.home{
    #navbar {
        /*padding-top: 1.52%;*/
        /*padding-bottom: 30px;*/
        background-color:  $deep_green; /*FFac9f*/
        background-image: none;
        min-height: 0;
        height: vh(8.4);
        /*opacity: 0.5;*/
        z-index: 0;	/*for dialogs*/
        // padding-top: vh(1.8);
        // padding-bottom: vh(1.8);
        @media (orientation: landscape){
			height: vhl(8.4);
            // height: 11%;
		}

		.localizer {
			display: flex;
			float: right;
			padding-top: 1px;
			opacity: 0.7;
			height: 100%;

			.dropdown {
				height: 100%;
				display: flex;
				align-items: center;

				.localizerSelector {
					display: block;
					cursor: pointer;
				}
				img {
					height: vh(4);
					@media (orientation: landscape){
						height: vw(4);
					}
				}

				.dropdown-menu {
					min-width: 6vh;
					top: 30%;
					> li > a {
						padding: 3px 1vh;
					}
				}
			}

		}
    }

    nav.container {
        display: block;     // for vert-align of the logo
        height: 100%;
        padding-left: 5vw;
        padding-right: 5vw;
    }

    .navbar-header {
		margin-left: auto;
		margin-right: auto;
        display: inline-block;
        vertical-align: middle;
        float: none;
        margin-top: 0;
        width: 100%;
        height: 70%;
		text-align: center;

		img#logo {
			margin-top: 0.1em;
		}
    }


    #firstStrip {
        background-color: $light_green;
        height: vhl(0.75);
        @media (orientation: portrait){
            height: vh(0.75);
        }
	}
	
	/*Bad design alert*/
	#badDesignAlert {
		background-color: $yellow;
		background-image: none;
		font-size: 1.2rem;
		.close {
			margin-right: 0.5em;
			opacity: 0.5;
		}
		@media (max-width: 768px) {
			display: none;
		}
	}

    /*MAIN PART*/
    #mainContainer {
        width: 100%;	/*for iPhone 4*/
        // overflow-x: hidden;
    }

    #middleSloganContainer {
        height: vh(11.8);
        // padding-top: 1.8%;
        // padding-bottom: 3.5%;
        color: $blackish;
        text-align: center;
        padding-right: vh(1);
        font-size: vh(5);
        font-family: 'Exo 2', sans-serif;
        font-weight: 200;
        letter-spacing: -1px;
		background-color: white;

		@media (orientation: landscape){
	        height: vhl(11.8);
			padding-right: vw(1);
			font-size: vw(5);
		}

        >h1 {
            display: inline-block;
            vertical-align: middle;
			text-transform: lowercase;
			font-size: inherit;
			font-weight: inherit;
			padding-bottom: vh(1.3);
			margin: 0;

			@media (orientation: landscape){
				padding-bottom: vw(1.3);
			}

            .strong {
                font-weight: 500;
                color: $deep_green;
            }

            // .it {
            //     font-style: italic;
            // }
        }

    }

    #listenerAndMethod {
		// height: vh(78.5);
		display: flex;
		align-items: center;
        overflow-x: hidden;
		overflow-y: auto; /*so that the children are wrapped*/
		background-color: $light_green;
		overflow: visible;
		

		@media (orientation: portrait){
			flex-direction: column;
		}

		@media (orientation: landscape){
			height: 70vh;
		}

        #listenerContainer {
			padding: 0;

			@media (orientation: portrait){
				display: inline-block;	//make it centered
				height: vh(48.5);

			}
			@media (orientation: landscape){
				display: block;	//make it centered
				align-self: stretch;
			}

            #listener {
                object-fit: cover;
				width: 100%;
				height: 100%;
            }
        }

        #lowerPart {
			padding: 1.5em 3em;
			overflow: visible;

            @media (orientation: portrait){
                width: 100%;
                min-height: vh(30.6);
			}

			@media (orientation: landscape) and (max-width: 800px){
				padding: 5% 4%;
			}

            .libo {
                margin-left: 1.5em;
				width: 5em;
				@media (min-width: 768px) {
					display: none;
				}
            }

            #aboutTheMethod {
                text-align: left;
                display: inline-block;
				vertical-align: middle;
				font-size: 1.5rem;
                text-transform: lowercase;
				margin-top: 0;
				/* font-size: 14px;  */
				// font-size: vh(2.5);
				/* line-height: 20px; */
				// line-height: vh(3.3);
				margin-left: 4.0%;
				padding-right: 0;
				width: 60%;

                @media (orientation: portrait) {
				}

				@media (min-width: 768px) {
					text-align: center;
					margin: 0;
					width: auto;
				}

				// @media (orientation: landscape){
				// 	// margin-left: 6%;
				// 	width: 50%;
				// 	min-width: 10rem;
				// 	// line-height:  vw(3.3);
				// 	// font-size: vw(2.5);
				// }
            }

            #startButtonRow{
                text-align: center;
                margin-top: vh(1.6);
				@media (orientation: landscape){
					margin-top: vh(10);
				}
            }

        }

    }

    .no-need {
        .no-need-item {
            position: relative; //for absolute image
            margin-top: vh(7);

            .no-need-image {
                position: absolute;
                width: 13.5vw;
                top: vh(1);
				left: 9.5vw;

				@media (orientation: landscape){
					width: 5vw;
				}

				&.ball {
					// top: -0.5vh;
					left: 8.5vw;
				}

				&.watch{
					width: 12.5vw;

					@media (orientation: landscape){
						width: 7vw;
					}
				}
            }

            .no-need-description {
                margin-left: 28vw;
				padding-right: 15vw;
				// padding-top: 1vh;

				@media (orientation: landscape){
                	margin-left: 18vw;
					padding-right: 5vw;
				}

                .strong {
                    color: $deep_green;
                    font-weight: 500;
                }
            }
		}

		padding-bottom: 1vh;
    }

    .two-steps {
        margin-top: vh(7);
		background-color: $light_green;
		padding-bottom: vh(6);
		// height: vh(118);

		@media (orientation: landscape){
        	margin-top: vw(7);
			padding-bottom: vw(6);
		}

        .two-steps-item {
            position: relative; //for absolute image
			margin-top: vh(6);
			@media (orientation: landscape) {
				margin-top: vw(6);
			}
			&.passive {
				margin-top: vh(5);
				@media (orientation: landscape) {
					margin-top: vw(6);
					margin-bottom: vw(5);
				}
			}

            .two-steps-image {
                position: absolute;
                width: 13.5vw;
                top: vh(3);
				left: 9.5vw;

				@media (orientation: landscape) {
					width: 13.5vw;
					left: 6.5vw;
				}

				&.passive{
					top: vh(4);
				}
            }

            .two-steps-description {
                margin-left: 28vw;
				padding-right: 15vw;

				@media (orientation: landscape){
					margin-top: vw(10);
					margin-left: 7vw;
					margin-right: 6vw;
					padding-right: 0;
				}

                h2 {
                    text-transform: lowercase;
                    // font-size: vh(4);
					margin-top: -vh(1);
					@media (orientation: landscape){
						// font-size: vw(4);
					}
                }

                .strong {
                    color: $deep_green;
                    font-weight: 500;
                }
            }
        }

	}

	.reference {
		background-color: $yellow;
		padding: 3em 3em 1em 5em;
		font-size: 1.2rem;
		// text-align: right;
		font-style: italic;
		.author {
			text-align: right;
			margin-top: 1em;
			line-height: 1em;
			padding-right: 1em;
			font-size: 1.1rem;
			margin-bottom: 1em;
		}
	}

	.already, .teacher {
		padding-left: 7vw;
		padding-right: 6vw;
		.home-description{
			width: 100%;
			padding-right: 15vw;
		}
	}

	.vera-wrapper {
		img {
			width: 100%;
		}
	}

	.science, .bonus-description {
		// background-color: $light_green;
		margin-top: vh(5);
		padding-left: 12vw;
		padding-right: 12vw;
		margin-bottom: vh(5);
	}

	.difference {
		width: 100%;
		td {
			text-align: center;
			border: 1px solid $deep_green;
			padding-top: vh(2);
			padding-bottom: vh(2);
			font-size: vh(2.3);
			line-height: vh(2.6);
			@media (orientation: landscape){
				font-size: vw(2.1);
				line-height: vw(2.6);
			}
		}

		tr:first-child td {
			background-color: $light_green;
			text-transform: lowercase;
		}

		tr:first-child td:first-child {
			background-color: $grey;
		}

		.plus {
			background-color: $light_green;
			color: $deep_green;
			font-weight: 900;
			font-size: 1.4rem;
		}

		.minus {
			background-color: $grey;
			color: $deep_green;
			font-weight: 900;
			font-size: 1.4rem;
		}

		.flyent {
			color: $deep_green;
			font-weight: 600;
			font-size: vh(3);
			@media (orientation: landscape){
				font-size: vw(3);
			}
		}
	}

	.lastCTA {
		background-color: $light_green;
		padding-top: vh(0.1);		//to avoid margin collapse
		padding-bottom: vh(6);
	}

    .smile-and-start{
		clear: both;
        margin-top: vh(6);

        .libo{
            margin-top: vh(1);
            margin-left: 14vw;
            width: 22vw;
        }

        .startButtonContainer{
            width: 50%;
            margin-left: 1%;

        }

    }

    .home-header {
		background-color: $deep_green;
		padding: 0 0 0.3em 0;
        color: $yellow;
        text-align: center;
        text-transform: lowercase;
        font-size: 2.8rem;
		font-weight: 300;
	}

	.home-content{
		padding-top: vh(4);
		padding-bottom:  vh(3);

		&.teacher{
			padding-bottom:  vh(5);
		}
	}

	.home-subheader{
		margin-top: 0;
		margin-left: 12vw;
		font-size: vh(3.7);
		font-weight: 500;
		margin-bottom: vh(1.5);
		@media (orientation: landscape){
			margin-left: 0;
			text-align: center;
			font-size: vw(3.7);
		}
	}

	.home-description {
		margin-left: 10vw;
		font-size: vh(2.5);
		width: 75%;
		line-height: vh(4);
		@media (orientation: landscape){
			margin: auto
		}
	}

	[class$="description"] {
		font-size: 1.5rem;
		line-height: 2.2rem;

		@media (orientation: landscape) {
			// font-size: vw(2.5);
			// line-height: vw(3.6);
			text-align: left;
		}

		.strong {
			font-weight: 500;
			font-size: 1.5rem;
			color: $deep_green_sat;
			// @media (orientation: landscape){
			// 	font-size: vw(2.5);
			// }
		}
	}


	h2 {
		text-transform: lowercase;
		font-size: 2.3rem;
		margin-top: 0;
		margin-left: 0;
	}


	//reset the Bootstrap's padding
	[class*="col-"] {
		padding-left: 0;
		padding-right: 0;
	}

	//reset the Bootstrap's margins
	.row{
		margin-left: 0;
		margin-right: 0;
	}

	//force sm columns to always display next to each other in landscape mode (bootstrap's default is 768px)
	@media (orientation: landscape){
		.col-sm-4 {
			width: 33.33333333%;
		}
		.col-sm-6 {
			width: 50%;
		}
		.col-sm-8 {
			width: 66.66666667%;
		}
		[class*="col-sm-"]{
			float: left;
		}
	}


    .startButtonContainer {
        display: inline-block;
        // width: 45%;
        text-align: center;
        vertical-align: middle;
        // padding-right: 11%;

        @media (orientation: portrait){
            // padding-left: 0;
            // padding-right: 0;
            // margin-top: vh(1);
            // margin-bottom: 5%;
            // width: 90px;
            /*padding-bottom: 6px; to vert-center the button properly*/
        }


        .btn {
            display: inline-block;
            vertical-align: middle;
            padding: 0.4em 1.2em 0.5em;
            background-color: $yellow;
            background-image: none;
            border: 1px solid white;
            border-radius: 20px;

            color: $blackish;
            white-space: initial;
            font-weight: 300;

            .try {
                text-transform: uppercase;
                font-weight: 500;
            }

            .free {
                font-style: italic;
                font-size: larger;
                font-weight: 100;
            }

            @media (orientation: portrait){
				width: 100%;
                // font-size: vh(3);
                // line-height: vh(3.7);
                // padding-top: vh(1.1);
                // padding-bottom: vh(1.4);
				border-radius: vh(5);

                @media (max-height: 600px) {
                    // font-size: 16px;    //so that the letters don't jump
                }
			}

			@media (orientation: landscape) {
				// font-size: vw(3);
                // line-height: vw(3.7);
				// width: 80%;
				// padding-left: 2vw;
				// padding-right: 2vw;
                // padding-top: vw(1.1);
                // padding-bottom: vw(1.4);
					// line-height: vw(5.7);
			}

			font-size: vf(3);
			line-height: 1;
        }
    }

}

.sendEmailDialog {
	.sendEmailText {
		margin-top: 1.5em;
		line-height: 1.7;
	}

	.input-group {
		margin: 1.5em 0;
	}

	.modal-body {
		padding: 0.5em 1.5em;
	}

	.modal-footer {
		padding: 1em 1.5em;
		background-color: $grey;
	}
}


.bonus-help-link {
	font-weight: 400;
	cursor: pointer;
	text-decoration: underline;
	color: $deep-green;
}

.bonus-container {
	width: 90%;
	padding: 1em 1em;
	margin: 0.5em auto;
	text-align: center;
	border-radius: 1em;
	font-size: 1.8rem;
	line-height: 1.2em;

	em {
		font-weight: 600;
		font-style: normal;
	}

	.bonus-help {
		font-size: 1.6rem;
		text-transform: lowercase;
		line-height: 1.2em;
		padding: 0 1em;
	}
}

.bonus-container-yellow {
	background-color: $yellow;
}

.bonus-container-green {
	background-color: $light_green;
}

.bonus-container-blue {
	background-color: $bluish;
}

.bonus-number {
	background-color: white;
	width: 1.5em;
	margin: 1em auto 0.2em auto;
	border-radius: 50%;
}

.modal-title {
	text-align: center;
	font-size: 1.8rem;
}

.modal-content .modal-header {
	border-style: none;
	padding-bottom: 0em;
	padding-top: 1.5em;
}

.modal-body {
	padding-top: 0.1em;
	padding-bottom: 0.5em;
}

.modal-footer {
	border-style: none;
	padding-top: 0;
	padding-bottom: 1.5em;
}

.footer {
	background-color: $grey;
	font-size: vh(2.0);
	padding: 2em 6em;
}

//


.vcenter:before {
    content:'';
    display:inline-block;
    height:100%;
    vertical-align:middle;
}


#copyright {
    font-weight: bold;
    font-size: 12px;
    color: #5A5A5A
}