$deep_green: #86ac9f;
$deep_green_sat: #48AC82;
$light_green: #d2f0e6;
$light_green_transparent: #d2f0e688;
$bluish: #D2F0E6;
$blackish: #505050;
$grey: #ebebeb;
$yellow: #fffac8;

//fix for devtools behavior -- rescale all vh
@function vh($size) {
    // @return $size * 1vh;
    @return $size * 0.91vh;
}

@function vw($size) {
	@return $size * 1.0vw;
}
