@import './colors';



html {
    margin-right: calc(-100vw + 100%);
	overflow-x: hidden;
	background-color: white;
}

body {
  height: 100%;
  font-family: 'Exo 2', sans-serif;
  color: $blackish;
}

/*add padding to the container so that the support button doesn't cover stuff on phones*/
.container {
	padding-left: 7%;
	padding-right: 7%;

	@media (min-width: 768px) {
		padding-left: 11%;
		padding-right: 11%;
	}
}

.mainLayout .container-fluid {
	width: 74%;
	max-width: 60em;
	position: relative;		//for red dot
}

.mainLayout #mainContainer {
	position: relative;	/*for abs positioned elements*/
	height: 90%;		/*we need to set the height so that we can have a fixed height for captions*/
	padding-top: 20px;
}

/*custom navbar*/
.navbar-default {
	background-color: $deep_green;
	background-image: none;
	border-style: none;
}

.navbar {
	font-size: 1.4rem;
	height: 4em;
	margin-bottom: 0;	/*reset the bootstrap value*/

	// @media (orientation: landscape) {
	// 	height: vw(6.3);
	// }
	/*z-index: 0;	for dialogs*/ //don't put z-index because it'll create a new stacking context and everything with z-index will still appear behind the main content
}

nav {
	height: 100%;
    display: flex;
    align-items: stretch;
}

.navbar-brand {
	padding: 0;
    float: none;
	display: flex;
	align-items: center;
	height: auto;
    // vertical-align: top;
	/* width: 50%; */
}

.navbar-brand a {
	display: inline-block;
	vertical-align: middle;
	height: 60%;
}

#logo, #back {
	height: 100%;
}

#backToStart {
	color: $light_green;
	font-size: 2.5rem;
	font-weight: 100;
	line-height: 1.2em;
}

/*top menu*/
.navbar-default .navbar-nav>li>a, .navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover {
	color: #FFFBCC;
}

#topMenu {
    flex-grow: 1;
	margin-left: 0.5em;
	padding-right: 0.5em;
	
	display: flex;
	align-items: center;
	// max-width: 16em;

    #langSwitcherWrapper{
        height: 100%;
        // width: 8vh;    //otherwise it'll be zero width because of the floating lang switcher elements
		display: flex;
		align-items: center;
		// padding-top: 2vh;
	}


    #moduleAndTitle {
        margin-top: 0.2em;
        margin-left: 1.3em;
		margin-right: 1.3em;
		width: 5em;
		flex-grow: 1;
		flex-shrink: 1;
        // width: 75%;
		// display: inline-block;

		.shorten {
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow-x: hidden;

		}


		#moduleNameTop {
			margin-top: 2px;
			margin-bottom: -4px;
			font-size: 1.6rem;
			font-weight: 100;
			a {
				text-transform: lowercase;
				color: #d3ece7; 
				cursor: pointer;
			}
		}

		#title{
			color: white;
			// display: inline-block;
			// max-width: 45vw;
			margin-bottom: 3px;
			font-size: 1.6rem;
		}


	}

}

// .students.start #topMenu {
// 	flex-grow: 0;
// }


/*Language chooser on the top*/
.langChooser {

	height: 2.1em;
	width: 3.9em;
	vertical-align: middle;
	font-size: 1.5rem;

	/*dropdown items*/
	.dropdown-menu {
		background-color: $deep_green;
		width: 100%;
			font-size: inherit;
		min-width: 0;
		right: auto;

		li {
			height: 2.1em;
			// padding-top: 1vh;
			// padding-bottom: 1vh;
			font-size: inherit;
			margin: 0.5em 0.05em;
		}

		li a {
			// height: 40px;
			// width: 40px;
			position: relative;
			padding-left: 0;
			padding-right: 0;
		}
	}

}

// .langChooser .dropdown-menu li {
//     padding: 3px;
// }

.langPair {
	position: relative;
	width: 100%;
	height: 100%;
	cursor: pointer;
	// @media (orientation: landscape) {
	// 	width: vw(7);
	// }

	.langSwitcherBg {
		position: absolute;
		top: 0;
		// width: vh(7.8);
		height: 100%;
		// @media (orientation: landscape) {
		// 	width: vw(7);
		// }
	}

	.lang {
		display: inline-block;
		position: relative;		//or we won't see it behind the background
		top: 0;
		width: 50%;
		height: 100%;
		vertical-align: top;	//avoid whitespace above
		line-height: 200%;
		// width: vh(3.9);
		text-align: center;
		color: $deep_green;
		// padding-top: vh(0.45);
		// padding: vh(0.1) vh(0.9);
		// line-height: vh(4.2);

		// @media (orientation: landscape) {
		// 	font-size: vw(2.3);
		// }

		&.learnedLang {
			// width: vh(4.2);
			padding-left: 10%;
			// @media (orientation: landscape) {
			// 	width: vw(4.2);
			// 	padding-left: w(0.4);
			// }
		}

		&.knownLang {
			// width: vh(3.6);
			padding-right: 5%;	//because it's asymmetric
			// @media (orientation: landscape) {
			// 	// width: vw(3.6);
			// 	padding-right: vw(0.4);
			// }
		}
	}
}

/*LANGUAGES*/
.lang-ar, .lang-he {
	direction: rtl;
}

.avatarAndRedDotWrapper {
	display: flex;
	height: 4.0rem;
	position: relative;
	align-self: center;

	.avatarWrapper {

		min-width: 4rem;
		border-radius: 50%;
		overflow: hidden;
		text-align: center;
		
		.avatar {
			font-size: 4.0rem;
			color: $light_green;
			cursor: pointer;
			// line-height: 200%;
			// margin: 0 0.7em;
		}

		img.avatar {
			height: 1em;
		}

	}
}


.redDot {
	position: absolute;
	right: 0.1rem;
	top: 0.1rem;
	width: 1rem;
	height: 1rem;
	background-color: #D65959;
	border-radius: 100%;
}

/* menu animation */
.modal.fade.accountMenuDialog .modal-dialog {
	transform: translate(25%, 0);
}

.modal.in.accountMenuDialog .modal-dialog {
	transform: translate(0, 0);
}


#firstStrip {
	background-color: $light_green;
	height: vh(1);
	@media (orientation: portrait){
		height: vh(0.75);
	}
}


.lightBg {
	background-color: #d3ece7;
}

/*PANEL STYLES*/
.panel {
	border-style: none;
	border-radius: 0;
	box-shadow: none;
}

.panel-heading, .panel-group .panel-heading {
	text-align: center;
	border-radius: 10px;
	padding-top: 0;
	padding-bottom: 5px;
	padding-left: 0;
	padding-right: 0;
	margin: 0;
	display: flex;
	align-items: center;
	background-color: $light_green;
	background-image: none;
}

.panel-heading a {
	flex: 1 0 auto;
}

.panel-heading a:hover, .panel-heading a:focus {
	text-decoration: none;
}

.panel-heading h4 {
	margin: 0;
	text-transform: lowercase;
	color: #6f9185;
	display: inline-block;
	vertical-align: middle;
	line-height: 1;
	flex: 1 0 auto;
}

.panel-heading i {
	font-size: 36px;
	height: 28px;
	color: #abcdc4;
	line-height: 28px;
}

.panel-heading [class^="icon-"]:before, [class*=" icon-"]:before {
    position: relative;
    top: 0px;
	width: auto;
	line-height: 30px;
	margin-right: .4em;
	margin-left: .4em;
}

/*reset bootstrap's top border'*/
.panel-group .panel-heading+.panel-collapse>.panel-body {
	border-style: none;
}

/*redefine bootstrap's border radius*/
.panel-group .panel, .panel {
    border-radius: 10px;
}

/* -- secondary color*/
/*vertical align:middle support*/
.vcenter:before, .panel-heading:before {
	content:'';
	display:inline-block;
	height:100%;
	vertical-align:middle;
}

h3, h4, a {
	color: #86ac9f;
}

/*override the Cookie Consent style to fit it on mobile screens*/
body #cc-notification h2 {
    margin-right: 50px!important;
}

/*bootstrap override*/
hr {
	margin: 0;
}

/*no-theme dialog*/
md-dialog {
	background-color: white;
}

md-backdrop.md-dialog-backdrop {
	background-color: rgb(33, 33, 33);
}

@media (max-width: 800px) {

	/* .navbar {
		height: 55px;
    } */

    #topMenu{

        #moduleNameTop {
            margin-top: 2px;
            margin-bottom: -4px;
            font-size: 14px;
        }

        #title{
            margin-bottom: 3px;
        }
    }


    .container-fluid {
        width: 94%;
    }

	.navbar-brand a {
        position: relative;
        left: -3px;
        top: -1px;
    }
 }

@media (max-width: 480px) {

	#mainContainer{
		margin-left: 0;
		margin-right: 0;
        width: 100%;

    }

    .mainLayout #mainContainer{
        padding-left: 7%;
        padding-right: 7%;
    }

	.navbar {
        // height: 7.8%;
        padding-left: 2%;
        padding-right: 2%;
	}

	.panel-heading i {
		font-size: 32px; height: 28px; color: #abcdc4;
    }

    .mainLayout .container-fluid {
        width: 100%;
    }

}

@media(min-width: 800px) {
	#gorizont_mob, #vertikal_mob {
		display: none;
	}
}

@media(orientation: landscape) and (max-width: 800px){
    /* so that we have enough space for captions on Player */
    #secondStrip {
        display: none;
    }

    .mainLayout #mainContainer{
        padding-top: 0;
        width: 95%;
    }

    #originalAndTranslated {
        height: 60%;
    }

}


/*button styles*/
.btn {background-image: none;}
.btn:focus, .btn:active:focus, a:focus, a:active, button:focus {
	outline: none;	/*to override the bootstrap style*/
	background-image: none;
}

.btn-default {
	text-shadow: none;
	
}

.btn-success {
	color: white;
	background-color: $deep_green;
	background-image: none;
	border-style: none;
	padding-top: 5px;
	padding-bottom: 7px;
	&:hover, &:active, &:focus {
		background-color: $deep_green;
		background-image: none;
		color: white;
	}
}

#attribution {
	clear: both;
	font-size: 0.8rem;
	text-align: center;
	padding: 3em 0 1em 0;
}