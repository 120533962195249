@import '../../../common/ui/colors';

#helpWrapper {
	// display: inline-block;
	flex-grow: 1;
	text-align: right;
	// margin-top: 1.5vh;
	// vertical-align: top;
	// height: 100%;
	// margin-left:auto;
	// margin-right:0;
	// float: right;

	#helpButton {
		font-size: 1.5rem;
		height: 1.6em;
		// width: 4vh;
		padding: 0 0.5em;
		line-height: 1.2em;
		margin: 0 0.2em;
		// box-sizing: content-box; /*same as above*/
		border-radius: 2vh;
		border: 1px solid $light_green_transparent;
		background-color: $deep_green;
		cursor: pointer;
		color: $light_green;
		text-transform: lowercase;
		font-weight: 100;

	}

}
